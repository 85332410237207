import React from 'react'
import PropTypes from "prop-types";

const Swiper = ({ hoverImage }) => {
    return (
        <div className="swiper-container blog-swiper">
            <div className="swiper-wrapper">
                {hoverImage.map(image => {
                    return (
                        <div className="swiper-slide" key={image.id}>
                            <div className="blog-swiper-slide">
                                <img src={image.fluid.src} alt="Blog" className="img-fluid" />
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <!-- Add Pagination --> */}
            <div className="swiper-pagination"></div>
        </div>
    )
}

Swiper.propTypes = {
    hoverImage: PropTypes.object
}

export default Swiper

import React from 'react'
import Image from 'gatsby-image';
import PropTypes from "prop-types";

import SocialAccount from '../../../constant/socialaccount';

const BloggerDetails = ({ bloggerImage, bloggerName, bloggerBio }) => {
    return (
        <div className="blogger-details">
            <div className="blogger-img">
                <Image fluid={bloggerImage.fluid} />
            </div>
            <div className="blogger-info">
                <h5>{bloggerName}</h5>
                <p>{bloggerBio}</p>
                <div className="social-connects">
                    {SocialAccount.map(social => (
                        <a
                            key={social.id}
                            href={social.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-ic"
                        >
                            {social.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

BloggerDetails.propTypes = {
    bloggerImage: PropTypes.object,
    bloggerName: PropTypes.string,
    bloggerBio: PropTypes.string

}

export default BloggerDetails

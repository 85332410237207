import React from 'react'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa"

export default [
    {
        id: 1,
        link: 'https://www.facebook.com',
        name: <FaFacebookF />
    },
    {
        id: 2,
        link: 'https://www.linkedin.com',
        name: <FaLinkedinIn />,
    },
    {
        id: 3,
        link: 'https://www.twitter.com',
        name: <FaTwitter />,
    }
]

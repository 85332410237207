import React from 'react'
import PropTypes from "prop-types";

import Swiper from './Swiper';

const Details = ({ title, description, hoverImage }) => {
    return (
        <article>
            <h1 className="title">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            <div className="blog-swiper-wraper">
                <Swiper hoverImage={hoverImage} />
            </div>
        </article>
    )
}

Details.propTypes = {
    hoverImage: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default Details

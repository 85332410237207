import React, { useEffect } from "react"
import Swiper from "swiper/js/swiper"
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Details from '../components/Blog/BlogDetails/Details';
import BloggerDetails from '../components/Blog/BlogDetails/BloggerDetails';

const timer = () => {
    new Swiper(".blog-swiper", {
        // speed: 400,
        loop: true,
        direction: "vertical",
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    })
}

const BlogTemplate = ({ data }) => {
    useEffect(() => {
        timer()
    }, [])

    const { title, description: { description }, hoverImage, bloggerDetail: { bloggerName, bloggerImage, bloggerBio: { bloggerBio } } } = data.blog
    return (
        <Layout>
            <div className="blog-details-page">
                <div className="blog-detail-banner" style={{ backgroundImage: `url(${hoverImage[0].fluid.src})` }} />
                <div className="blog-details-content">
                    <div className="container">
                        <div className="blog-details-content-row">
                            <Details
                                title={title}
                                description={description}
                                hoverImage={hoverImage}
                            />

                            <BloggerDetails
                                bloggerName={bloggerName}
                                bloggerImage={bloggerImage}
                                bloggerBio={bloggerBio}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const blogsBySlugQuery = graphql`
query($slug:String!){
    blog:contentfulBlog(slug:{eq:$slug}){
        title
        description{
            description
        }
        hoverImage{
            id
            fluid{
                ...GatsbyContentfulFluid
            }
        }
    bloggerDetail:bloggerid{
        bloggerName:name
      bloggerImage:profileImage{
        fluid{
            ...GatsbyContentfulFluid
        }
      }
      bloggerBio:bio{
        bloggerBio:bio
        }
      }
    }
  }`;

export default BlogTemplate
